<template>

  <b-card-code
    title="Create Student"
  >
   <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <ValidationObserver v-slot="{ handleSubmit}">
  <b-form @submit.prevent="handleSubmit(storeStudentProfile)">
    <b-alert
      variant="danger"
      show
      dismissible
      v-if="validationErrors && validationErrors.message"
    >
      <div class="alert-body">
        <span><strong>{{validationErrors.message}}</strong></span>
      </div>
    </b-alert>
      <b-row class="mt-2">
        <b-col md="6">
          <b-form-group
            label="Username"
            label-for="mc-first-name"
          >
            <ValidationProvider rules="required" name="Nombre" v-slot="{ errors }">
              <b-form-input
                v-model="user.name"
                id="mc-first-name"
                placeholder="Username"
                name="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Email"
          >
            <div class="form-label-group">
              <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
                <b-form-input
                    id="register-email"
                    v-model="user.email"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger" v-if="validationErrors && validationErrors.validationErrors.email.length">
                  {{ validationErrors.validationErrors.email[0] }}
                </small>
              </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
        <b-form-group
                label-for="register-password"
                label="Password"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                  vid="confirmation"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.password"
                      
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
              <!-- confirm password -->
              <b-col md="6">
              <b-form-group
                label-for="register-confirm-password"
                label="Confirm Password"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:confirmation"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.confirm_password"
                      
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
        <!-- submit and reset -->
        <b-col>
        
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            
          >
            Create
          </b-button>
         
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver> 
  </b-overlay>
   </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BFormDatepicker, BInputGroup, BAlert, BInputGroupAppend, BCol, BAvatar,BCardText, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormFile,BMedia, BMediaAside, BOverlay, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthService from '@/services/auth.service.js';
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormFile,
    BCardText,
    BFormDatepicker,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BAlert
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
      },
      logoImg: require('@/assets/images/pages/logo-1.png'),
      avatar: '@/assets/images/portrait/small/avatar-s-11.jpg',
      showOverlay: false,
      errors: ''
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    validationErrors(){
      return this.errors.data
    },
  },
  methods: {
    storeStudentProfile() {  
      this.showOverlay = true;
          AuthService.registration(this.user).then(response => {
            this.showOverlay = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Student Created Successfully',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.$router.push('/students');
            }).catch(error => {
              this.showOverlay = false;
              this.errors = error.response
            });
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
